body
{
  width: 100%;
  font-family: Roboto, serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
}





.site-select {
  background-color: #f8f8f8;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-select-container{
  width: 410px;
  top: 50%;
  left: 50%;

}

.site-select-container-title{
  margin: 0 0 32px 0;
  height: 30px;
  text-align: center;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);;
}


.site-select-dialog{
  width: 410px;
  /* min-height: 380px;*/
  height: fit-content;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 30px;
  line-height: 32px;
  text-align: center;
}



.site-select-dialog button{
  width: 100%;
  font-size: 16px !important;
  border-radius: 4px;
  border: solid 1px #0067f3;
  background-color: #0067f3;
}

.site-select-dialog label{
  color: #373a3c;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.site-select-dialog-row{
  margin: 30px 0 0 0 ;
}

.site-select-dialog-action-row{
  margin: 16px 0 0 0 ;
}

.site-select-dialog a{
  color: #0275d8;
  text-align: center;
  cursor: pointer;
}


.stage-title {
  font-size: 28px;
  text-align: center;
  color: #000000;
}

.link{
  color: #0067f3;
  cursor: pointer;
}




